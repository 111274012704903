//
// Base styles
//

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: none;
  @include border-radius($card-border-radius);

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }
  .dropdown {
    opacity: 0;
  }
  @include hover-focus {
    &:not(.nested) {
      .overflow-hidden {
        > .card-thumb {
          transform: scale(1.05);
        }
      }
      .dropdown {
        opacity: 1;
      }
    }
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $card-spacer-x;
  color: $card-color;
}

.card-title {
  margin-bottom: $card-spacer-y;
  color: var(--primaryText);
  font-weight: 400;
  a {
    display: block;
    color: inherit;
  }
  @at-root {
    a#{&} {
      display: block;
      color: inherit;
    }
  }
}

.card-subtitle {
  margin-top: -$card-spacer-y / 2;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  @include hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

//
// Optional textual caps
//

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }

  + .list-group {
    .list-group-item:first-child {
      border-top: 0;
    }
  }
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
}

//
// Header navs
//

.card-header-tabs {
  margin-right: -$card-spacer-x / 2;
  margin-bottom: -$card-spacer-y;
  margin-left: -$card-spacer-x / 2;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -$card-spacer-x / 2;
  margin-left: -$card-spacer-x / 2;
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.card-img {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-radius($card-inner-border-radius);
}

// Card image caps
.card-img-top {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-top-radius($card-inner-border-radius);
  transition: all 0.2s linear;
}

.card-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-bottom-radius($card-inner-border-radius);
}

// Card deck

.card-deck {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      display: flex;
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}

//
// Card groups
//

.card-group {
  display: flex;
  flex-direction: column;

  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-right-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-blacklist
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-blacklist
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-left-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-blacklist
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-blacklist
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

//
// Accordion
//

.accordion {
  > .card {
    overflow: hidden;

    &:not(:first-of-type) {
      .card-header:first-child {
        @include border-radius(0);
      }

      &:not(:last-of-type) {
        border-bottom: 0;
        @include border-radius(0);
      }
    }

    &:first-of-type {
      border-bottom: 0;
      @include border-bottom-radius(0);
    }

    &:last-of-type {
      @include border-top-radius(0);
    }

    .card-header {
      margin-bottom: -$card-border-width;
    }
  }
}
.card-dark {
  background-color: $card-bg-dark;
  .card-body {
    color: $card-color-dark;
  }
  .card-title {
    color: inherit;
  }
  .card-footer {
    background-color: $card-cap-dark-bg;
  }
}

//
//
.catalogueCard {
  .card-footer {
    // background: #ffffff;
    padding: 2px;
    .btn {
      &:not(:first-child) {
        margin-left: 2px;
      }
    }
  }

  &:hover {
    .thumbImg {
      transform: scale(1.05);
    }
  }
}
.thumbImg {
  transition: all 0.2s linear;
}

.tile-thumb {
  max-height: 168px;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}
.tile-action {
  position: absolute;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  transition: all 0.2s ease;
  color: $body-color;
  width: 30px;
  height: 13px;
  right: 1rem;
  top: 1rem;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}
.catalog-card {
  min-height: 337px;
}
.card-tertiary {
  .card-title {
    margin-bottom: 0;
  }
  .card-block-heading {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}
.blogCard {
  height: 100%;
  min-height: 349px;
  .card-body {
    padding: 1rem 0.8rem;
  }
}
.sun-editor-editable {
  color: var(--primaryText) !important;
}
.banner_preview {
  position: relative;
  display: block;
  width: 200px;
   .edit-span {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
    opacity: 0;
    transition: all 0.2s ease;
  }
  &:hover {
     .edit-span {
      opacity: 1;
    }
  }
}

.card-subscribe {
  position: fixed;
  width: 380px;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  padding: 30px;
  transform: translateY(0);
  transition: all ease 0.5s;
}
.hide-card-subscribe {
  transform: translateY(-200px);
}
.card-subscribe-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(#000, 0.5);
}
