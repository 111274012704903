.media {
  display: flex;
  align-items: flex-start;
}

.media-heading {
  margin-bottom: 0;
}
.media-body {
  flex: 1;
}

.playlist-media-container {
  box-sizing: content-box;
  padding: 0.25rem;
  border: 1px solid var(--playlistBorder);
  background-color: var(--cardBg);
  border-radius: $border-radius;
  // background-color: darken($dark, 7%);
}
.palyer-wrapper {
  height: 200px;
}
.playlist-media {
  transition: all 0.2s linear;
  cursor: pointer;
  &:hover,
  &.active {
    background-color: var(--playlistHover);
  }
}
.playlist-media {
  padding: 10px;
}
.media-group {
  overflow: auto;
  max-height: 250px;
}

.bg-dark {
  .playlist-media-container {
    background-color: #27293d;
    border-color: darken($dark, 7%);
  }
  .playlist-media {
    &:hover,
    &.active {
      background-color: darken($dark, 1%);
    }
  }
}
