.home-wrapper {
  color: #fff;
}
.home-search-bar {
  position: relative;
  height: 50px;
  input {
    border: 0;
    background: none;
    height: 50px;
    opacity: 0;
    position: relative;
    z-index: 1;
    color: #fff;
    flex-grow: 0;
    width: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 200;
    &::placeholder {
      color: rgba(#fff, 0.5);
    }
    &:focus {
      outline: none !important;
    }
  }
  .inputWrapper {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 50px;
    transition: all 0.2s linear;
    display: flex;
    cursor: pointer;
    align-items: center;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 1px;
      background-color: #fff;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s linear;
    }
  }
  .searchBtn {
    color: #fff;
    border: none;
    background: transparent;
    pointer-events: none;
    font-size: 25px;
    transition: all 0.2s linear;
    padding-left: 10px;
    padding-right: 10px;
    > svg {
      width: 2rem;
      display: inline-block;
      font-size: inherit;
      fill: currentColor;
    }
  }
  &.focused {
    > .inputWrapper {
      width: 450px;
      cursor: default;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      &:after {
        left: 0;
        right: 0;
        opacity: 1;
        visibility: visible;
      }
    }
    input {
      opacity: 1;
      flex-grow: 1;
      padding: 10px;
    }
    .searchBtn {
      font-size: 18px;
      pointer-events: all;
      > svg {
        width: 1.325rem;
      }
    }
  }
}

.home-heading {
  animation: fadeDrop 0.85s linear;
}

.backgroundEffect {
  // background: linear-gradient(46deg, #9942ab, #fa69b6, #feac74);
  // background-size: 250% 100%;
  // -webkit-animation: gradientEffect 10s linear infinite alternate;
  // -moz-animation: gradientEffect 10s linear infinite alternate;
  // -o-animation: gradientEffect 10s linear infinite alternate;
  // animation: gradientEffect 10s linear infinite alternate;
  // background-attachment: fixed;
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(ellipse at bottom, lighten(#191970, 10%) 0%, #09094d 100%);
  overflow: hidden;
}

@-webkit-keyframes gradientEffect {
  0% {
    background-position: 0% 96%;
  }
  50% {
    background-position: 100% 5%;
  }
  100% {
    background-position: 0% 96%;
  }
}
@-moz-keyframes gradientEffect {
  0% {
    background-position: 0% 96%;
  }
  50% {
    background-position: 100% 5%;
  }
  100% {
    background-position: 0% 96%;
  }
}
@-o-keyframes gradientEffect {
  0% {
    background-position: 0% 96%;
  }
  50% {
    background-position: 100% 5%;
  }
  100% {
    background-position: 0% 96%;
  }
}
@keyframes gradientEffect {
  0% {
    background-position: 0% 96%;
  }
  50% {
    background-position: 100% 5%;
  }
  100% {
    background-position: 0% 96%;
  }
}
.cardBlocksGroup {
  display: flex;
  flex-wrap: wrap;
}
.card-block {
  height: 100px;
  padding: 20px 15px;
  flex: 1 0 25%;
  color: #c2c2c2;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  transition: background 0.4s ease;
  border-left: 1px solid #e1e1e1;
  @at-root a#{&} {
    @include hover-focus {
      font-weight: 600;
      background-color: $primary;
      color: #fff;
      text-decoration: none;
    }
  }
  @include media-breakpoint-down(sm) {
    flex: 1 0 50%;
  }
  &:first-child {
    border-left: 0;
    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
      padding: 10px;
      height: auto;
      border-bottom: 1px solid #e1e1e1;
    }
  }
  &:nth-child(0n + 5) {
    border-left: 0;
  }
  &:nth-child(n + 5) {
    border-top: 1px solid #e1e1e1;
  }
}
.card-block-heading {
  background-color: #f8f8f8;
  color: #005ba1;
  .card-title {
    color: inherit;
  }
}
.btn-big {
  background-color: #fff;
  color: #c2c2c2;
  padding: 25px 15px;
  border: none;
  text-decoration: none;
  font-size: 18px;
  display: block;
  text-align: center;
  transition: $btn-transition;
  @include hover-focus {
    text-decoration: none;
    background-color: $primary;
    color: #fff;
    font-weight: 600;
  }
}

@function multiple-box-shadow($n) {
  $value: "#{random(2000)}px #{random(2000)}px #FFF";
  @for $i from 2 through $n {
    $value: "#{$value} , #{random(2000)}px #{random(2000)}px #FFF";
  }

  @return unquote($value);
}

$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);
.stars {
  opacity: 0.6;
  border-radius: 100%;
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation: animStar 50s linear infinite;
  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
  }
}
#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation: animStar 100s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
  }
}
#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  animation: animStar 150s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
  }
}
@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
.clearBtn {
  background-color: transparent;
  color: inherit;
  border: none;
  display: none;
  width: 35px;
  height: 35px;
  transition: all 0.5s ease;
  border-radius: 3px;
  @include hover-focus {
    outline: none;
    background-color: rgba(#fff, 0.3);
  }
  .clearable & {
    display: block;
  }
}
.slick-arrow-button.outline {
  background-color: transparent;
  color: $primary;
  .slick-next &:after {
    border-color: $primary $primary rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  }
  .slick-prev &:after {
    border-color: $primary rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $primary;
  }
}
