// stylelint-disable selector-no-qualifying-type
//
// Base styles
//
.btn {
	display: inline-block;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	color: $body-color;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: $btn-border-width solid transparent;
	@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
	@include transition($btn-transition);
	@include hover {
		color: $body-color;
		text-decoration: none;
	}
	&:focus,
	&.focus {
		outline: 0;
		box-shadow: $btn-focus-box-shadow;
	}
	// Disabled comes first so active can properly restyle
	&.disabled,
	&:disabled {
		opacity: $btn-disabled-opacity;
		@include box-shadow(none);
	}
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		@include box-shadow($btn-active-box-shadow);
		&:focus {
			@include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
		}
	}
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
	pointer-events: none;
}

//
// Alternate buttons
//
@each $color,
$value in $theme-colors {
	.btn-#{$color} {
		@include button-variant($value, $value);
	}
}

@each $color,
$value in $theme-colors {
	.btn-outline-#{$color} {
		@include button-outline-variant($value);
	}
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
	font-weight: $font-weight-normal;
	color: $link-color;
	text-decoration: $link-decoration;
	@include hover {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}
	&:focus,
	&.focus {
		text-decoration: $link-hover-decoration;
		box-shadow: none;
	}
	&:disabled,
	&.disabled {
		color: $btn-link-disabled-color;
		pointer-events: none;
	}
	// No need for an active state here
}

//
// Button Sizes
//
.btn-lg {
	@include button-size( $btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
	@include button-size( $btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}

.btn-secondary {
	color: $primary;
	@include hover {
		color: $primary;
	}
	&:not(:disabled):not(.disabled):active {
		color: $primary;
	}
}

//
// Block button
//
.btn-block {
	display: block;
	width: 100%;
	// Vertically space out multiple block buttons
	+.btn-block {
		margin-top: $btn-block-spacing-y;
	}
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
	&.btn-block {
		width: 100%;
	}
}

%slick-btn {
	border: 1px solid rgba($primary, 0.5);
	border-radius: 100%;
	width: 30px;
	height: 30px;
	position: relative;
	display: inline-block;
	vertical-align: top;
	&:after {
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	&:hover,
	&:focus {
		background-color: rgba($primary, 1);
	}
}

.playBtn {
	position: relative;
	border-radius: 10rem;
	min-width: 74px;
	height: 30px;
	box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.35);
	&:after {
		content: "";
		position: absolute;
		display: block;
		border: 6px solid;
		left: 7px;
		right: 0;
		top: 1px;
		bottom: 0;
		margin: auto;
		height: 7px;
		width: 7px;
		border-color: transparent transparent transparent #fff;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	&:hover,
	&:focus {
		&:after {
			border-color: transparent transparent transparent #fff;
		}
	}
}

.slick-arrow-button {
	@extend %slick-btn;
	background-color: rgba($primary, 0.75);
	&:after {
		content: "";
		position: absolute;
		display: block;
		border: 1px solid;
		height: 10px;
		width: 10px;
		margin: auto;
		top: 0;
		bottom: 0;
		right: 0;
		.slick-prev & {
			left: 5px;
			transform: rotate(-45deg);
			border-color: #fff transparent transparent #fff;
		}
		.slick-next & {
			left: -5px;
			transform: rotate(45deg);
			border-color: #fff #fff transparent transparent;
		}
	}
}

.slick-arrow {
	outline: none !important;
	width: 30px !important;
	height: 30px !important;
	top: 47% !important;
}

@include media-breakpoint-up(lg) {
	.slick-next {
		right: -35px !important;
	}
	.slick-prev {
		left: -35px !important;
	}
}