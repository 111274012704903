@import "utilities/align";
@import "utilities/background";
@import "utilities/borders";
@import "utilities/clearfix";
@import "utilities/display";
@import "utilities/embed";
@import "utilities/flex";
@import "utilities/float";
@import "utilities/overflow";
@import "utilities/position";
@import "utilities/screenreaders";
@import "utilities/shadows";
@import "utilities/sizing";
@import "utilities/stretched-link";
@import "utilities/spacing";
@import "utilities/text";
@import "utilities/visibility";

.slick-arrow {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: $primary;
  border: none;
  outline: none;
  z-index: 1;
  background: transparent;
  font-size: 25px;
  outline: none;
  .bg-dark & {
    color: #fff;
  }
}
.slick-next {
  right: 0;
}
.slick-prev {
  left: 0;
}
.slick-disabled {
  display: none !important;
}
.seemore-slide {
  border: 1px solid rgba(#000, 0.09);
  height: 338px;
  [data-theme="dark"] & {
    border: 1px solid rgba(#fff, 0.09);
  }
}
.slick-list {
  padding-bottom: 20px;
  padding-top: 10px;
}
.toast-notification {
  z-index: $zindex-tooltip !important;
}
body.bg-dark {
  color: #fff;
}
.themeBtn {
  // height: 35px;
  // line-height: 1;
  color: #fff !important;
  transition: none !important;
  box-shadow: none !important;
  .bg-dark & {
    background-color: #34344a;
  }
}
#main-content {
  table {
    @extend .table;

    > tbody > tr > td:nth-child(2) {
      font-weight: 500;
    }
    p {
      margin-bottom: 0;
    }
  }
  > p:first-child {
    display: none;
  }
  h1 {
    display: none;
  }
  h2 {
    font-weight: 300;
    margin-top: 1.5rem;
    font-size: 1.25rem;
  }
}

.navigationIcon {
  width: 50px;
  fill: #005ba1;
}

.social-icon{
  cursor: pointer;
}