.userDropDown {
    color: inherit;
    &:before {
        content: "";
        width: 1px;
        height: 22px;
        background-color: var(--navbarBorderColor);
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
    }
}

