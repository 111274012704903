:root {
  // Custom variable values only support SassScript inside `#{}`.
  @each $color, $value in $colors {
    --#{$color}: #{$value};
  }

  &[data-theme="dark"] {
    @each $color, $value in $dark-theme-colors {
      --#{$color}: #{$value};
    }
  }
  @each $color, $value in $theme-colors {
    --#{$color}: #{$value};
  }

  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }

  // Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --font-family-sans-serif: #{inspect($font-family-sans-serif)};
  --font-family-monospace: #{inspect($font-family-monospace)};
  font-size: 15px;
}
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?p9bpix");
  src: url("../fonts/icomoon.eot?p9bpix#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?p9bpix") format("truetype"), url("../fonts/icomoon.woff?p9bpix") format("woff"),
    url("../fonts/icomoon.svg?p9bpix#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


// html.theme-transition,
// html.theme-transition *,
// html.theme-transition *:before,
// html.theme-transition *:after {
//   transition: all 750ms !important;
//   transition-delay: 0 !important;
// }