.sidebar {
  max-width: 250px;
  width: 250px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(#fff, 0.85);
  z-index: 1031;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transform: translateX(400px);
  transition: transform 0.28s linear;
  &Open {
    transform: translateX(0);
  }
  &Dark {
    background-color: rgb(30, 30, 47);
  }
  :global {
    .tile-thumb {
      height: auto;
    }
    .border {
      [data-theme="dark"] & {
        border-color: transparent !important;
      }
    }
  }
}

.overLay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1030;
}
.close-btn {
  display: flex;
  height: 35px;
  width: 35px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
    [data-theme="dark"] & {
      background-color: rgb(30, 30, 47);
    }
  }
}

.sidebarBody {
  padding: 15px;
  overflow: auto;
  height: calc(100% - 66px);
}
.sidebarHeader {
  background-color: var(--sidebarheaderBg);
  padding: 15px;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.btn-sidebar {
  position: fixed;
  right: 0;
  top: 180px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transform: translateX(10px);
  padding-right: 30px;
  padding-left: 18px;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: transform 0.4s ease;
  z-index: 5;
  &:hover {
    transform: translateX(0);
  }
  &.btnHidden {
    transform: translateX(150%);
  }
}
